import { ToastContainer } from "react-toastify";
import "./App.css";
import RouteComponent from "./routes/index";
import "react-toastify/dist/ReactToastify.css";
import { LoggedIn, loggedInData } from "./data/fakeLoginData";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "./pages/login";
import { useSelector } from "react-redux";

function App() {
  const navigate = useNavigate();
  const language = useSelector(state => state.language)
  useEffect(() => {
    if (!LoggedIn) navigate("/login");
  }, [loggedInData, LoggedIn]);
  if (language == "en") {
    document.body.classList.add("en_version")
  } else {
    document.body.classList.remove("en_version")
  }
  return (
    <div className="App">
      {!LoggedIn ? (
        <Routes>
          <Route path="Login" element={<Login />} />
        </Routes>
      ) : (
        <RouteComponent />
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
