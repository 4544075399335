import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home";
import Sections from "../pages/Sections";
import WebsiteDatas from "../pages/Website_Data";
import Services from "../pages/Services";
import HowWork from "../pages/HowWork";
import UserData from "../pages/UserData";
import Doctor from "../pages/doctor";
import AddDoctor from "../pages/adddoctor";
import Persons from "../pages/Person_Data";
import ArchivedDoctor from "../pages/doctor/archivedDoctors";
import ArchiverPersons from "../pages/Person_Data/ArchiveData";

function RouteComponent() {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Home />} />
        <Route path="Doctor" element={<Doctor />} />
        <Route path="doctor/adddoctor" element={<AddDoctor />} />
        <Route path="persons" element={<Persons />} />
        <Route path="sections" element={<Sections />} />
        <Route path="website_data" element={<WebsiteDatas />} />
        <Route path="services" element={<Services />} />
        <Route path="how_work" element={<HowWork />} />
        <Route path="user_data" element={<UserData />} />
        <Route path="ArchivedDoctor" element={<ArchivedDoctor />} />
        <Route path="ArchiverPersons" element={<ArchiverPersons />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

export default RouteComponent;
