import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";
import Modal from "../../components/modal";
import axios from "axios";
import { base_url } from "../../data/consts";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { ExcelIcon } from "../../assets/tabler-icons/excelIcon";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function ArchiverPersons() {
  const language = useSelector((state) => state.language);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [fromDate, setFromDate] = useState(false);
  const [toDate, setToDate] = useState(false);
  const [doctorId, setDoctorId] = useState(null);
  const [personId, setPersonId] = useState("");
  const [agent, setAgent] = useState([]);
  const [ArchiveKey, setArchiveKey] = useState(false);
  const ArchiveDoctor = async () => {
    setLoading(true);
    const disableAgent = await axios.post(base_url + "edit_user_archive.php", {
      submation_id: ArchiveKey?.submation_id,
      archive: 0,
    });
    if (disableAgent.data.status == "success") {
      toast.success("تم الحذف من الأرشيف بنجاح");
      getPersons();
      setArchiveKey(false);
    } else {
      toast.error("فشلت العملية");
    }
    setLoading(true);
  };

  useEffect(() => {
    getAgents();
    getPersons();
  }, []);

  useEffect(() => {
    filterData();
  }, [fromDate, toDate, doctorId, personId, data]);

  const getAgents = async () => {
    const select = await axios.get(base_url + "select_agents.php");
    setAgent(select?.data?.message);
  };

  const getPersons = async () => {
    const select = await axios.get(base_url + "select_archive_users_subme.php");
    setData(select?.data?.message);
  };

  const filterData = () => {
    let filtered = data.filter((item) => {
      // Extract the timestamp of the fromDate and toDate
      const fromTimestamp = fromDate ? new Date(fromDate).getTime() : null;
      const toTimestamp = toDate ? new Date(toDate).getTime() : null;

      // Extract the timestamp of the item's created_at date
      const itemTimestamp = new Date(item.created_at).getTime();

      // If toDate is provided, add 24 hours to include data up to the end of that day
      let endOfDayTimestamp = null;
      if (toTimestamp) {
        const endOfDay = new Date(new Date(toDate).setHours(23, 59, 59, 999));
        endOfDayTimestamp = endOfDay.getTime();
      }

      return (
        (!fromDate || itemTimestamp >= fromTimestamp) &&
        (!toDate || itemTimestamp <= endOfDayTimestamp) &&
        (!doctorId || item?.agent_id == doctorId) &&
        (!personId ||
          item?.submation_id?.includes(personId) ||
          item?.submation_name?.includes(personId) ||
          item?.phone?.includes(personId) ||
          item?.agent_name?.includes(personId))
      );
    });
    setFilteredData(filtered);
  };

  const searchByPersonId = () => {
    if (!personId) {
      toast.warn(
        language === "ar"
          ? "من فضلك أدخل رقم عملية"
          : "Please Add Process Number"
      );
      return;
    }
    setDoctorId(null);
    filterData();
  };

  const exportToCSV = (apiData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const headers = [
    {
      label: language === "ar" ? "تحديد" : "Select",
      type: "children",
      children: ({ row }) => (
        <input
          checked={row?.checked}
          type="checkbox"
          onClick={() => {
            row.choosed = !row?.choosed;
            if (row?.choosed) choosedDocs.push(row);
            else
              choosedDocs = choosedDocs.filter(
                (item) => item?.submation_id !== row.submation_id
              );
            setChoosedDocs([...choosedDocs]);
          }}
        />
      ),
    },
    {
      label: language === "ar" ? "رقم العملية" : "Proccess Number",
      dataIndex: "submation_id",
    },
    {
      label: language === "ar" ? "تاريخ العملية" : "Proccess Date",
      dataIndex: "created_at",
    },
    {
      label: language === "ar" ? "الاسم" : "Name",
      dataIndex: "submation_name",
    },
    {
      label: language === "ar" ? "رقم التليفون" : "Mobile",
      dataIndex: "phone",
    },
    {
      label: language === "ar" ? "المبلغ المراد تقسيطه" : "Budget Needed",
      dataIndex: "budget_needed",
    },
    {
      label: language === "ar" ? "مصدر الدخل" : "Income Source",
      dataIndex: "sourse_income",
    },
    {
      label: language === "ar" ? "الدخل الشهري" : "Monthly Income",
      dataIndex: "monthly_income",
    },
    {
      label: language === "ar" ? "الحصول على تأمين طبي" : "Medical Insurance",
      dataIndex: "medical_insurance",
    },
    {
      label: language === "ar" ? "الوكيل" : "Agent",
      dataIndex: "agent_name",
    },
    {
      label: language === "ar" ? "التفاصيل" : "Details",
      dataIndex: "deatils",
    },
    {
      label: language == "ar" ? "أوامر" : "Actions",
      type: "actions",
      actions: [
        {
          label: language == "ar" ? "إلغاء الأرشفة" : "UnArchive Doctor",
          action: ({ item }) => setArchiveKey(item),
        },
      ],
    },
  ];

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="add-container">
              <div className="div">
                <ContentNav head={language === "ar" ? "الأشخاص" : "Persons"} />
              </div>
              <div className="pageHeaderLeftSide">
                <span
                  className="tabler-Icons"
                  onClick={() => setShowExport(true)}
                >
                  <ExcelIcon />
                </span>
              </div>
            </div>
            <div className="filters_box formTypeTwo">
              <div className="inputField">
                <label htmlFor="fromDate">
                  {language === "ar" ? "من" : "From"}
                </label>
                <input
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="inputField">
                <label htmlFor="toDate">
                  {language === "ar" ? "إلى" : "To"}
                </label>
                <input
                  type="date"
                  name="toDate"
                  id="toDate"
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              <div className="inputField">
                <label htmlFor="doctorId">
                  {language === "ar" ? "اختيار الوكيل" : "Select Agent"}
                </label>
                <select
                  name="doctorId"
                  id="doctorId"
                  onChange={(e) => setDoctorId(e.target.value || null)}
                >
                  <option value="">{language === "ar" ? "الكل" : "All"}</option>
                  {agent &&
                    agent.map((item) => (
                      <option key={item.agent_id} value={item.agent_id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="inputField searchByPersonId">
              <label htmlFor="personId">
                {language === "ar" ? "بحث  " : "Search"}
              </label>
              <input
                type="text"
                name="personId"
                id="personId"
                onChange={(e) => setPersonId(e.target.value)}
              />
              <button className="btn btn-primary" onClick={searchByPersonId}>
                {language === "ar" ? "بحث" : "Search"}
              </button>
            </div>
            <Table
              headers={headers}
              body={filteredData}
              classess={["table-tc"]}
            />
            <Modal
              open={showExport}
              toggle={setShowExport}
              headerTitle={
                language === "ar"
                  ? "تصدير البيانات كملف إكسيل"
                  : "Export Data To Excel File"
              }
              children={
                <div className="flex-box">
                  {choosedDocs && choosedDocs.length ? (
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        exportToCSV(choosedDocs, "Clients Data");
                      }}
                    >
                      {language === "ar"
                        ? "تصدير المحدد فقط"
                        : "Export Only Selected"}
                    </button>
                  ) : null}
                  <button
                    className="btn btn-primary"
                    width={"fit-content"}
                    onClick={() => {
                      exportToCSV(data, "Clients Data");
                    }}
                  >
                    {language === "ar" ? "تصدير الكل" : "Export All Data"}
                  </button>
                </div>
              }
            />
            <Modal
              open={ArchiveKey}
              toggle={setArchiveKey}
              headerTitle={language == "ar" ? "حذف من الأرشيف" : "UnArchive"}
              children={
                <div className="flex-box">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      ArchiveDoctor();
                    }}
                  >
                    {language == "ar" ? "تأكيد" : "Confirm"}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setArchiveKey(false);
                    }}
                  >
                    {language == "ar" ? "إلغاء" : "Cancel"}
                  </button>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default ArchiverPersons;
