import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  LoggedIn,
  fakeCredintials,
  loggedInData,
} from "../../data/fakeLoginData";
import { useSelector } from "react-redux";
import axios from "axios";

function Login() {
  const language = useSelector((state) => state.language);

  const navigate = useNavigate();

  const [loginLoading, setLoginLoading] = useState(false);
  const login = (e) => {
    setLoginLoading(true);
    const email = e.currentTarget.email.value;
    const pass = e.currentTarget.password.value;
    axios
      .post("https://baseeta-form.com/basita/login_admin.php", {
        email: email,
        pass,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success("تم تسجيل الدخول بنجاح");
          localStorage.setItem("logAuth", JSON.stringify(res.data.message));
          setTimeout(() => {
            if (JSON.parse(localStorage.getItem("logAuth"))) {
              window.location.href = "/Message_History";
            }
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };
  useEffect(() => {
    if (loggedInData) navigate("/");
  }, []);

  return (
    <Fragment>
      <div className="authContainer">
        <div className="authFunction">
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
              login(e);
            }}
          >
            <div className="inputField">
              <label htmlFor="email">
                {language == "ar" ? "البريد الالكتروني" : "ُEmail"}
              </label>
              <input type="text" id="email" name="email" />
            </div>
            <div className="inputField">
              <label htmlFor="password">
                {language == "ar" ? "كلمة السر" : "Password"}{" "}
              </label>
              <input type="password" id="password" name="password" />
            </div>

            <button className="btn btn-success">
              {language == "ar" ? "تسجيل الدخول" : "Log in"}
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
