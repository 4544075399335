import React, { useEffect, useState } from "react";

import Table from "../../../components/table";
import "../../Sections/style.css";
import "../../Services/style.css";

import Modal from "../../../components/modal";
import { Loader } from "rsuite";
import axios from "axios";
import { toast } from "react-toastify";

function Education() {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [eduaction, setEducations] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (showMessage) {
      setEducations(
        data?.filter(
          (item) => item?.eduaction_id == showMessage?.eduaction_id
        )[0]
      );
    }
    setLoading(false);
  }, [showMessage]);

  const [showEdit, setShowEdit] = useState(false);
  const editEducations = (e) => {};

  const [openAdd, setOpenAdd] = useState(false);
  const addEducations = (e) => {};
  const [openDelete, setOpenDelete] = useState(false);

  const selectEducation = async () => {
    setLoading(true);
    const select = await axios.get(
      "https://camp-coding.tech/alaqra_profile/admin/education/select_educations.php"
    );
    setData(select?.data?.message);
    setLoading(false);
  };

  useEffect(() => {
    selectEducation();
  }, []);

  const addEducation = async (e) => {
    setLoading(true);
    const data_send = {
      description: e.currentTarget.eduaction_description.value,
      period: e.currentTarget.eduaction_date.value,
      title: e.currentTarget.eduaction_title.value
    };
    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/education/add_educations.php",
      JSON.stringify(data_send)
    );

    if (select?.data?.status && select?.data?.status == "success") {
      toast.success(select?.data?.message);
      selectEducation();
      setOpenAdd(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };

  const editEducation = async (e) => {
    setLoading(true);
    console.log(showEdit);
    const data_send = {
      eduaction_id: showEdit?.item?.id,
      description: e.currentTarget.eduaction_description.value
        ? e.currentTarget.eduaction_description.value
        : showEdit?.item?.description,
      period: e.currentTarget.eduaction_date.value
        ? e.currentTarget.eduaction_date.value
        : showEdit?.item?.period,
      title: e.currentTarget.eduaction_title.value
        ? e.currentTarget.eduaction_title.value
        : showEdit?.item?.period
    };
    console.log("data_send", data_send);
    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/education/update_educations.php",
      JSON.stringify(data_send)
    );

    console.log(select);
    if (select?.status?.length || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      selectEducation();
      setShowEdit(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };

  const deleteEducations = async (e) => {
    setLoading(true);
    const data_send = {
      education_id: openDelete?.item?.id
    };

    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/education/delete_educations.php",
      JSON.stringify(data_send)
    );

    console.log(select);
    if (select?.status?.length || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      selectEducation();
      setOpenDelete(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };

  const headers = [
    {
      label: "عنوان التعليم",
      dataIndex: "title"
    },
    {
      label: "المدة",
      dataIndex: "period"
    },
    {
      label: "معلومات إضافية",
      dataIndex: "description",
      type: "children",
      children: ({ headers, row }) => {
        return <p className="big_text">{row?.description}</p>;
      }
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض ",
          action: ({ item }) => setShowMessage(item)
        },
        {
          label: " تعديل بيانات ",
          action: setShowEdit,
          class: "btn-primary"
        },
        {
          label: "حذف",
          action: setOpenDelete,
          class: "btn-danger"
        }
      ]
    }
  ];

  return (
    <div className="childs">
      <button
        className="btn btn-primary"
        onClick={() => setOpenAdd(true)}
        style={{ margin: "20px 0" }}
      >
        إضافة تعليم
      </button>
      {!loading ? (
        <Table headers={headers} body={data} classess={["table-tc"]} />
      ) : (
        <Loader />
      )}
      <Modal
        open={showMessage}
        toggle={setShowMessage}
        onClose={setEducations}
        headerTitle={"الخبرات"}
        children={
          <>
            {!loading ? (
              <div className="section">
                <div className="id">
                  <span> تعليم : </span>
                  <span>{eduaction?.title}</span>
                </div>
                <div className="id">
                  <span>المدة: </span>
                  <span>{eduaction?.period}</span>
                </div>
                <div className="id">
                  <span>معلومات لإضافية : </span>
                  <span>{eduaction?.description}</span>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </>
        }
      />

      <Modal
        open={showEdit}
        toggle={setShowEdit}
        headerTitle={"تعديل  "}
        children={
          <div className="eduaction">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                editEducation(e);
              }}
            >
              <div className="inputField">
                <label htmlFor="eduaction_title">عنوان التعليم</label>
                <input
                  type="text"
                  id="eduaction_title"
                  name="eduaction_title"
                  defaultValue={showEdit?.item?.title}
                />
              </div>
              <div className="inputField">
                <label htmlFor="eduaction_date">تاريخ التعلم</label>
                <input
                  type="text"
                  id="eduaction_date"
                  name="eduaction_date"
                  defaultValue={showEdit?.item?.period}
                />
              </div>
              <div className="inputField">
                <label htmlFor="eduaction_description">معلومات إضافية</label>
                <textarea
                  type="text"
                  id="eduaction_description"
                  name="eduaction_description"
                  defaultValue={showEdit?.item?.description}
                  rows={7}
                />
              </div>
              <button className="btn btn-success">تعديل</button>
            </form>
          </div>
        }
      />
      <Modal
        open={openAdd}
        toggle={setOpenAdd}
        headerTitle={"إضافة تعليم جديدة"}
        children={
          <div className="eduaction">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                addEducation(e);
              }}
            >
              <div className="inputField">
                <label htmlFor="eduaction_title">عنوان التعليم</label>
                <input
                  type="text"
                  id="eduaction_title"
                  name="eduaction_title"
                />
              </div>
              <div className="inputField">
                <label htmlFor="eduaction_date">تاريخ التعلم</label>
                <input type="text" id="eduaction_date" name="eduaction_date" />
              </div>
              <div className="inputField">
                <label htmlFor="eduaction_description">معلومات إضافية</label>
                <textarea
                  type="text"
                  id="eduaction_description"
                  name="eduaction_description"
                  rows={7}
                />
              </div>

              <button className="btn btn-success">إضافة</button>
            </form>
          </div>
        }
      />

      <Modal
        open={openDelete}
        toggle={setOpenDelete}
        headerTitle={"حذف تعليم"}
        children={
          <div className="service">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                deleteEducations(e);
              }}
            >
              <h1> هل أنت متأكد من حذف تعليم ؟</h1>
              <button className="btn btn-danger">حذف</button>
            </form>
          </div>
        }
      />
    </div>
  );
}

export default Education;
