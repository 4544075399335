import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import "./style.css";
import Person from "./person_data";
import SocialMedia from "./SocialMedia";
import Experience from "./experience";

function UserData() {
  const tabs = [
    // { id: 1, label: "بيانات شخصية" },
    { id: 2, label: "قصتي" },
    // { id: 3, label: "مواقع التواصل" },
  ];
  const [tabId, setTabId] = useState(2);
  const [tabData, setTabData] = useState(false);

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"بيانات الشخص"} />
            <div className="data__about">
              <div className="tabs">
                {tabs.map((item, index) => {
                  return (
                    <button
                      className={
                        item?.id == tabId
                          ? "btn tab__btn active"
                          : "btn tab__btn"
                      }
                      onClick={() => setTabId(item?.id)}
                    >
                      {item?.label}
                    </button>
                  );
                })}
              </div>
            </div>
            {tabId == 1 ? (
              <Person />
            ) : tabId == 3 ? (
              <SocialMedia />
            ) : tabId == 2 ? (
              <Experience />
            ) : null}
          </div>
        }
      />
    </div>
  );
}

export default UserData;
