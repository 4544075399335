import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layout/defaultlayout";
import ContentNav from "../../../datanavcontent";
import "../style.css";
import Education from "../Education";
import Skills from "../Skills";
import Experiences from "../Experiences";

function Experience() {
  const tabs = [
    { id: 1, label: "المهارات" },
    { id: 2, label: "الخبرة" },
    { id: 3, label: "التعليم" },
  ];
  const [tabId, setTabId] = useState(1);
  const [tabData, setTabData] = useState(false);

  return (
    <div className="childs">
      <div className="data__about exp">
        <div className="tabs">
          {tabs.map((item, index) => {
            return (
              <button
                className={
                  item?.id == tabId ? "btn tab__btn active" : "btn tab__btn"
                }
                onClick={() => setTabId(item?.id)}
              >
                {item?.label}
              </button>
            );
          })}
        </div>
      </div>
      {tabId == 3 ? (
        <Education />
      ) : tabId == 1 ? (
        <Skills />
      ) : tabId == 2 ? (
        <Experiences />
      ) : null}
    </div>
  );
}

export default Experience;
