import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";

import "./style.css";
import Modal from "../../components/modal";
import axios from "axios";
import { toast } from "react-toastify";

function WebsiteDatas() {
  const [data, setData] = useState();

  const select_services = async () => {
    const select = await axios.get(
      "https://camp-coding.tech/alaqra_profile/admin/categories_headers/select_categories_headers.php"
    );
    console.log(select?.data?.message?.footer);
    setData([select?.data?.message?.footer]);
  };

  useEffect(() => {
    select_services();
  }, []);

  const [showEdit, setShowEdit] = useState(false);

  const headers = [
    {
      label: "الواتساب",
      dataIndex: "whatsapp"
    },
    {
      label: "رقم التليفون",
      dataIndex: "phone"
    },
    {
      label: "العنوان",
      dataIndex: "address"
    },
    {
      label: "البريد الالكتروني",
      dataIndex: "email"
    },
    {
      label: "الفيسبوك",
      dataIndex: "facebook"
    },
    {
      label: "لينكدان",
      dataIndex: "linkedin"
    },
    {
      label: "تيك توك",
      dataIndex: "tiktok"
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        // {
        //   label: " عرض بيانات الموقع  ",
        //   action: setShowMessage
        // },
        {
          label: " تعديل بيانات الموقع ",
          action: setShowEdit,
          class: "btn-primary"
        }
      ]
    }
  ];

  const editWebsiteData = async(e) => {
    const send_data = {
      whatsapp:
        e.currentTarget.whatsapp.value && e.currentTarget.whatsapp.value.length
          ? e.currentTarget.whatsapp.value
          : showEdit?.item?.whatsapp,
      phone:
        e.currentTarget.phone.value && e.currentTarget.phone.value.length
          ? e.currentTarget.phone.value
          : showEdit?.item?.phone,
      address:
        e.currentTarget.address.value && e.currentTarget.address.value.length
          ? e.currentTarget.address.value
          : showEdit?.item?.address,
      email:
        e.currentTarget.email.value && e.currentTarget.email.value.length
          ? e.currentTarget.email.value
          : showEdit?.item?.email,
      facebook:
        e.currentTarget.facebook.value && e.currentTarget.facebook.value.length
          ? e.currentTarget.facebook.value
          : showEdit?.item?.facebook,
      linkedin:
        e.currentTarget.linkedin.value && e.currentTarget.linkedin.value.length
          ? e.currentTarget.linkedin.value
          : showEdit?.item?.linkedin,
      tiktok:
        e.currentTarget.tiktok.value && e.currentTarget.tiktok.value.length
          ? e.currentTarget.tiktok.value
          : showEdit?.item?.tiktok
    };

    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/categories_headers/update_footer.php",
      JSON.stringify(send_data)
    );

    console.log(select);
    if (select?.status?.length || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setShowEdit(false);
    } else {
      toast.error(select?.data?.message);
    }
  };
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"بيانات الموقع"} />
            {<Table headers={headers} body={data} classess={["table-tc"]} />}
            <Modal
              open={showEdit}
              toggle={setShowEdit}
              headerTitle={"تعديل بيانات الموقع"}
              children={
                <div className="website_data">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      editWebsiteData(e);
                    }}
                  >
                    {/* Additional fields */}
                    <div className="inputField">
                      <label htmlFor="whatsapp">الواتساب</label>
                      <input
                        type="text"
                        id="whatsapp"
                        name="whatsapp"
                        defaultValue={showEdit?.item?.whatsapp}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="phone">رقم التليفون</label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        defaultValue={showEdit?.item?.phone}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="address">العنوان</label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        defaultValue={showEdit?.item?.address}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="email">البريد الالكتروني</label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        defaultValue={showEdit?.item?.email}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="facebook">الفيسبوك</label>
                      <input
                        type="text"
                        id="facebook"
                        name="facebook"
                        defaultValue={showEdit?.item?.facebook}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="linkedin">لينكدان</label>
                      <input
                        type="text"
                        id="linkedin"
                        name="linkedin"
                        defaultValue={showEdit?.item?.linkedin}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="tiktok">تيك توك</label>
                      <input
                        type="text"
                        id="tiktok"
                        name="tiktok"
                        defaultValue={showEdit?.item?.tiktok}
                      />
                    </div>
                    {/* End of additional fields */}
                    <button className="btn btn-primary">تعديل</button>
                  </form>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default WebsiteDatas;
