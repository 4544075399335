import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Modal from "../../components/modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ExcelIcon } from "../../assets/tabler-icons/excelIcon";
import { base_url } from "../../data/consts";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function ArchivedDoctor() {
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const [data, setData] = useState();
  const [showMessage, setShowMessage] = useState(false);
  let [choosedDocs, setChoosedDocs] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState(false);

  useEffect(() => {
    getAgents();
  }, []);
  const getAgents = async () => {
    const select = await axios.get(base_url + "select_agents.php");
    setData(select?.data?.message?.filter((item) => item?.archive == 1));
  };
  const disable_enable_agent = async () => {
    setLoading(true);
    const disableAgent = await axios.post(base_url + "edit_archive.php", {
      agent_id: agent?.item?.agent_id,
      archive: parseInt(agent?.item?.archive) ? 0 : 1,
    });
    if (disableAgent.data.status == "success") {
      toast.success("تم الحذف من الأرشيف بنجاح");
      await getAgents();
      setAgent(false);
    } else {
      toast.error("فشلت العملية");
    }
    setLoading(true);
  };

  const headers = [
    {
      label: language == "ar" ? "تحديد" : "Select",
      type: "children",
      children: ({ row }) => {
        return (
          <input
            checked={row?.checked}
            type="checkbox"
            name=""
            id=""
            onClick={() => {
              row.choosed = !row?.choosed;
              if (row?.choosed) choosedDocs.push(row);
              else
                choosedDocs = [
                  ...choosedDocs.filter(
                    (item) => item?.login_id != row.login_id
                  ),
                ];
              setChoosedDocs([...choosedDocs]);
            }}
          />
        );
      },
    },
    {
      label: language == "ar" ? "الرقم التعريفي للوكيل" : "ID",
      dataIndex: "login_id",
    },
    {
      label: language == "ar" ? "إسم الوكيل" : "Agent Name",
      dataIndex: "name",
    },
    {
      label: language == "ar" ? "شعار الوكيل" : "Agent Image",
      type: "children",
      children: ({ row }) => {
        return (
          <span>
            <img src={row.image} alt="" />
          </span>
        );
      },
    },
    {
      label: language == "ar" ? "كلمة سر الوكيل" : "Password",
      dataIndex: "password",
    },
    {
      label: language == "ar" ? "رقم الهاتف" : "Mobile",
      dataIndex: "phone",
    },
    {
      label: language == "ar" ? "تحديد" : "Select",
      type: "children",
      children: ({ row }) => {
        return (
          <span>
            {parseInt(row?.disable) ? (
              <button className="btn btn-success" disabled>
                {language == "ar" ? " متاح للعمل" : "Working"}
              </button>
            ) : (
              <button
                className="btn btn-danger"
                style={{ whiteSpace: "nowrap" }}
                disabled
              >
                {language == "ar" ? " متوقف عن العمل" : "Stopped"}
              </button>
            )}
          </span>
        );
      },
    },
    {
      label: language == "ar" ? "أوامر" : "Actions",
      type: "actions",
      actions: [
        {
          label: language == "ar" ? "حذف من الأرشيف" : "UnArchive",
          action: setAgent,
        },
      ],
    },
  ];

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="add-container">
              <div className="div">
                <ContentNav head={language == "ar" ? "الأرشيف" : "Archive"} />
              </div>
            </div>
            <Table headers={headers} body={data} classess={["table-tc"]} />

            <Modal
              open={agent}
              toggle={setAgent}
              headerTitle={
                language == "ar" ? "حذف من الأرشيف" : "Delete From Archive"
              }
              children={
                <div className="flex-box">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      disable_enable_agent();
                    }}
                  >
                    {language == "ar" ? "تأكيد" : "Confirm"}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setAgent(false);
                    }}
                  >
                    {language == "ar" ? "إلغاء" : "Cancel"}
                  </button>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default ArchivedDoctor;
