import React from "react";
import ProfileMenu from "./profile-menu";
import "./style.css";
import { Icon } from "@iconify/react";
import { fakeCredintials, loggedInData } from "../../data/fakeLoginData";
import { useDispatch, useSelector } from "react-redux";
import { Globe } from "../../assets/images/svg/images";
import { changeLanguage } from "../../store/language";
function Header({ active, setActive }) {
  const dispatch = useDispatch();
  const authenticatedUser = fakeCredintials
  const language = useSelector(state => state.language)
  return (
    <header>
      <div className="div_header">
        <div className="list-toggle" onClick={() => setActive(!active)}>
          <img
            src="https://res.cloudinary.com/duovxefh6/image/upload/v1693606296/menu_s5rljg.png"
            alt=""
          />
        </div>
        <div className="person">
          <img src={fakeCredintials?.image} alt="" />
          <div className="person__data">
            <span>{fakeCredintials?.name}</span>
            <span style={{ color: "var(--main-color-1)" }}>{fakeCredintials?.jop}</span>
          </div>

        </div>
      </div>
      <div className="languageConverter" onClick={() => dispatch(changeLanguage(language == "ar" ? "en" : "ar"))}>
        <span>{language}</span>
        <span>{Globe}</span>
      </div>
      <ProfileMenu userData={authenticatedUser} />
    </header>
  );
}

export default Header;
