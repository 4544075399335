import React, { useEffect, useState } from "react";

import Table from "../../../components/table";
import "../../Sections/style.css";
import "../../Services/style.css";

import Modal from "../../../components/modal";
import { Loader } from "rsuite";

function SocialMedia() {
  const [data, setData] = useState([
    {
      social_media_id: "2",
      social_media_name: "هذا النص هو مثال لنص",
      social_media_link: "https://www.facebook.com",
      social_media_image:"https://res.cloudinary.com/duovxefh6/image/upload/v1693658403/facebook_ye5kjb.png",
    },
    {
      social_media_id: "3",
      social_media_name: "هذا النص هو مثال لنص",
      social_media_link: "https://www.facebook.com",
      social_media_image:"https://res.cloudinary.com/duovxefh6/image/upload/v1693658403/facebook_ye5kjb.png",
    },
    {
      social_media_id: "4",
      social_media_name: "هذا النص هو مثال لنص",
      social_media_link: "https://www.facebook.com",
      social_media_image:"https://res.cloudinary.com/duovxefh6/image/upload/v1693658403/facebook_ye5kjb.png",
    },
    {
      social_media_id: "5",
      social_media_name: "هذا النص هو مثال لنص",
      social_media_link: "https://www.facebook.com",
      social_media_image:"https://res.cloudinary.com/duovxefh6/image/upload/v1693658403/facebook_ye5kjb.png",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [social_media, setSocialMedias] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (showMessage) {
      setSocialMedias(
        data?.filter(
          (item) => item?.social_media_id == showMessage?.social_media_id
        )[0]
      );
    }
    setLoading(false);
  }, [showMessage]);

  const [showEdit, setShowEdit] = useState(false);
  const editSocialMedias = (e) => {};

  const [openAdd, setOpenAdd] = useState(false);
  const addSocialMedias = (e) => {};

  const [openDelete, setOpenDelete] = useState(false);
  const deleteSocialMedias = (e) => {
    const send_data = {
      social_media_id: openDelete,
    };
  };
  const headers = [
    {
      label: "اسم موقع التواصل",
      dataIndex: "social_media_name",
    },
    {
      label: "شعار موقع التواصل",
      dataIndex: "social_media_image",
      type: "children",
      children: ({ headers, row }) => {
        return <img src={row?.social_media_image} />;
      },
    },

    {
      label: "زيارة",
      dataIndex: "social_media_link",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <a
            href={row?.social_media_link}
            className="btn btn-primary"
            target="_blanck"
          >
            زيارة
          </a>
        );
      },
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض موقع التواصل  ",
          action: ({ item }) => setShowMessage(item),
        },
        {
          label: " تعديل بيانات موقع التواصل ",
          action: setShowEdit,
          class: "btn-primary",
        },
        {
          label: "حذف موقع التواصل",
          action: setOpenDelete,
          class: "btn-danger",
        },
      ],
    },
  ];

  return (
    <div className="childs">
      <button
        className="btn btn-primary"
        onClick={() => setOpenAdd(true)}
        style={{ margin: "20px 0" }}
      >
        إضافة موقع تواصل
      </button>
      <Table headers={headers} body={data} classess={["table-tc"]} />
      <Modal
        open={showMessage}
        toggle={setShowMessage}
        onClose={setSocialMedias}
        headerTitle={"بيانات موقع التواصل"}
        children={
          <>
            {!loading ? (
              <div className="section">
                <div className="id">
                  <span> اسم موقع التواصل : </span>
                  <span>{social_media?.social_media_name}</span>
                </div>
                
                <div className="id">
                  <span>زيارة موقع التواصل : </span>
                  <a
                    href={social_media?.social_media_link}
                    className="btn btn-primary"
                    target="_blanck"
                  >
                    زيارة
                  </a>

                </div>
                <div className="id" style={{ margin: "auto" }}>
                  <img
                    src={social_media?.social_media_image}
                    alt=""
                    width={"140px"}
                  />
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </>
        }
      />

      <Modal
        open={showEdit}
        toggle={setShowEdit}
        headerTitle={"تعديل بيانات موقع التواصل"}
        children={
          <div className="social_media">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                editSocialMedias(e);
              }}
            >
              <div className="inputField">
                <label htmlFor="social_media_name">اسم موقع التواصل</label>
                <input
                  type="text"
                  id="social_media_name"
                  name="social_media_name"
                  defaultValue={showEdit?.item?.social_media_name}
                />

              </div>
              <div className="inputField">
                <label htmlFor="social_media_description">
                  رابط موقع التواصل
                </label>
                <input
                  type="text"
                  id="social_media_name"
                  name="social_media_name"
                  defaultValue={showEdit?.item?.social_media_link}
                />
              </div>
              <div className="inputField type_image">
                <label htmlFor="social_media_image"> صورة موقع التواصل</label>

                <input
                  type="file"
                  id="social_media_image"
                  lang="ar"
                  name="social_media_image"
                />
              </div>
              <button className="btn btn-success">تعديل</button>
            </form>
          </div>
        }
      />
      <Modal
        open={openAdd}
        toggle={setOpenAdd}
        headerTitle={"إضافة موقع تواصل جديدة"}
        children={
          <div className="social_media">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                addSocialMedias(e);
              }}
            >
              <div className="inputField">
                <label htmlFor="social_media_name">اسم موقع التواصل</label>
                <input
                  type="text"
                  id="social_media_name"
                  name="social_media_name"
                />
              </div>
              <div className="inputField">
                <label htmlFor="social_media_link">رابط موقع التواصل</label>
                <input
                  type="text"
                  id="social_media_link"
                  name="social_media_link"
                />
              </div>
              <div className="inputField type_image">
                <label htmlFor="social_media_image"> صورة موقع التواصل</label>

                <input
                  type="file"
                  id="social_media_image"
                  lang="ar"
                  name="social_media_image"
                />
              </div>
              <button className="btn btn-success">إضافة</button>
            </form>
          </div>
        }
      />

      <Modal
        open={openDelete}
        toggle={setOpenDelete}
        headerTitle={"حذف موقع التواصل"}
        children={
          <div className="service">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                deleteSocialMedias(e);
              }}
            >
              <h1> هل أنت متأكد من حذف موقع التواصل ؟</h1>
              <button className="btn btn-danger">حذف</button>
            </form>
          </div>
        }
      />
    </div>
  );
}

export default SocialMedia;
