import React from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import "./style.css";
import { useSelector } from "react-redux";

function Home() {
  const language = useSelector(state => state.language)
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={language == "ar" ? "الرئيسية" : "Home"} />
          </div>
        }
      />
    </div>
  );
}

export default Home;
