import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "../Sections/style.css";
import "./style.css";

import Modal from "../../components/modal";
import { Loader } from "rsuite";
import axios from "axios";
import { toast } from "react-toastify";

function Services() {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const select_services = async () => {
    setLoading(true);
    const select = await axios.get(
      "https://camp-coding.tech/alaqra_profile/admin/services/select_services.php"
    );
    setData(select?.data?.message);
    setLoading(false);
  };

  useEffect(() => {
    select_services();
  }, []);
  const [showMessage, setShowMessage] = useState(false);
  const [service, setService] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (showMessage) {
      setService(
        data?.filter((item) => item?.service_id == showMessage?.service_id)[0]
      );
    }
    setLoading(false);
  }, [showMessage]);

  const [showEdit, setShowEdit] = useState(false);
  const editService = async (e) => {
    let uploadedImage = false;
    const formData = new FormData(e.target);
    uploadedImage = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/image_uplouder.php",
      formData
    );
    const send_data = {
      service_id: showEdit?.item?.id,
      title:
        e.target.service_title.value && e.target.service_title.value.length
          ? e.target.service_title.value
          : showEdit?.item?.title,
      description:
        e.target.service_description.value &&
        e.target.service_description.value.length
          ? e.target.service_description.value
          : showEdit?.item?.description,
      image_url: uploadedImage
        ? uploadedImage?.data
        : showEdit?.item?.image_url,
    };
    let select = {};
    if (uploadedImage?.data && uploadedImage?.data != "\n") {
      select = await axios.post(
        "https://camp-coding.tech/alaqra_profile/admin/services/update_service.php",
        JSON.stringify(send_data)
      );
    }
    if (select?.data?.status && select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setShowEdit(false);
    } else {
      toast.error("حدثت مشكلة");
    }
  };

  const [openAdd, setOpenAdd] = useState(false);
  const addService = async (e) => {
    let uploadedImage = false;
    const formData = new FormData(e.target);
    uploadedImage = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/image_uplouder.php",
      formData
    );
    const send_data = {
      title: e.target.service_title.value,
      description: e.target.service_description.value,
      service_image: uploadedImage?.data,
    };
    let select = {};
    if (uploadedImage?.data) {
      select = await axios.post(
        "https://camp-coding.tech/alaqra_profile/admin/services/add_service.php",
        JSON.stringify(send_data)
      );
    }
    console.log("select", select);
    if (select?.status == 200 || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setOpenAdd(false);
    } else {
      toast.error("حدثت مشكلة");
    }
  };

  const [openDelete, setOpenDelete] = useState(false);
  const deleteService = async (e) => {
    const send_data = {
      service_id: openDelete?.item?.id,
    };
    let select = {};
    select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/services/delete_service.php",
      JSON.stringify(send_data)
    );

    if (select?.data?.status && select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setOpenDelete(false);
    } else {
      toast.error("حدثت مشكلة");
    }
  };
  const headers = [
    {
      label: "عنوان الخدمة",
      dataIndex: "title",
    },
    {
      label: "وصف الخدمة",
      dataIndex: "description",
      type: "children",
      children: ({ headers, row }) => {
        return <p className="big_text">{row?.description}</p>;
      },
    },
    {
      label: "صورة تعبر عن الخدمة",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <img
            className="service_image"
            src={row?.image_url}
            alt=""
            width={"100px"}
          />
        );
      },
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض الخدمة  ",
          action: ({ item }) => setShowMessage(item),
        },
        {
          label: " تعديل بيانات الخدمة ",
          action: setShowEdit,
          class: "btn-primary",
        },
        {
          label: "حذف الخدمة",
          action: setOpenDelete,
          class: "btn-danger",
        },
      ],
    },
  ];

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"خدماتنا"} />
            <button
              className="btn btn-primary"
              onClick={() => setOpenAdd(true)}
              style={{ margin: "20px 0" }}
            >
              إضافة خدمة جديدة
            </button>
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={showMessage}
              toggle={setShowMessage}
              onClose={setService}
              headerTitle={"بيانات الخدمة"}
              children={
                <>
                  {!loading ? (
                    <div className="section">
                      <div className="id">
                        <span> عنوان الخدمة : </span>
                        <span>{service?.title}</span>
                      </div>
                      <div className="id">
                        <span>وصف الخدمة : </span>
                        <span>{service?.description}</span>
                      </div>
                      <div className="id" style={{ margin: "auto" }}>
                        <img src={service?.image_url} alt="" width={"140px"} />
                      </div>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </>
              }
            />

            <Modal
              open={showEdit}
              toggle={setShowEdit}
              headerTitle={"تعديل بيانات الخدمة"}
              children={
                <div className="service">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      editService(e);
                    }}
                  >
                    <div className="inputField">
                      <label htmlFor="service_title">عنوان الخدمة</label>
                      <input
                        type="text"
                        id="service_title"
                        name="service_title"
                        defaultValue={showEdit?.item?.title}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="service_description">وصف الخدمة</label>
                      <textarea
                        type="text"
                        id="service_description"
                        name="service_description"
                        defaultValue={showEdit?.item?.description}
                        rows={7}
                      />
                    </div>
                    <div className="inputField type_image">
                      <label htmlFor="image">صورة صغيرة تعبر عن الخدمة</label>

                      <input type="file" id="image" lang="ar" name="image" />
                    </div>
                    <button className="btn btn-success">تعديل</button>
                  </form>
                </div>
              }
            />
            <Modal
              open={openAdd}
              toggle={setOpenAdd}
              headerTitle={"إضافة خدمة جديدة"}
              children={
                <div className="service">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      addService(e);
                    }}
                  >
                    <div className="inputField">
                      <label htmlFor="service_title">عنوان الخدمة</label>
                      <input
                        type="text"
                        id="service_title"
                        name="service_title"
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="service_description">وصف الخدمة</label>
                      <textarea
                        type="text"
                        id="service_description"
                        name="service_description"
                        rows={7}
                      />
                    </div>
                    <div className="inputField type_image">
                      <label htmlFor="service_image">
                        صورة صغيرة تعبر عن الخدمة
                      </label>

                      <input
                        type="file"
                        id="service_image"
                        lang="ar"
                        name="service_image"
                      />
                    </div>
                    <button className="btn btn-success">إضافة</button>
                  </form>
                </div>
              }
            />

            <Modal
              open={openDelete}
              toggle={setOpenDelete}
              headerTitle={"حذف الخدمة"}
              children={
                <div className="service">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      deleteService(e);
                    }}
                  >
                    <h1> هل أنت متأكد من حذف الخدمة ؟</h1>
                    <button className="btn btn-danger">حذف</button>
                  </form>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default Services;
